import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { getProductsList, getSerialProgrammingCommand, postScannedMAC, runDeviceCalibration } from "../api/config";
import { http } from "../api/utils/http";
import { AppContext } from "../context/AppContext";
import {
  COMMAND_RESPONSE_STATUS,
  GetNextOperationResponse,
  GetProductListResponse,
  PassingValue,
  PostScannedMacResponse,
} from "../interfaces/be.interfaces";
import {
  CommunicationType,
  CustomCommandResultType,
  DeviceConfigurationPayload,
  Log,
  SendDeviceResponsePayload,
} from "../interfaces/fe.interfaces";
import {
  COMMAND_STATUS,
  HEX_LOG_COMMAND_NAMES,
  USA_REGION_CODE,
  userConfirmationMessage,
  PCM_FORMAT_ENTERPRISE_LABEL_MAP,
  BITS_PER_FRAME_SAMPLE_ENTERPRISE_LABEL_MAP,
  SAMPLE_RATE_ENTERPRISE_LABEL_MAP,
} from "../utils/constants";
import { formatMAC, removeScanner, watchScanner } from "../utils/scannerUtils";
import { MAC_ADDRESS_REGEX } from "../utils/validationSchemas";
import { usePrompt } from "./useCallbackPrompt";
import { useConnection } from "./useConnection";
import { useCustomerContext } from "./useCustomerContext";
import { useFetch } from "./useFetch";
import { SerialDriver } from "../utils/serial";
import { findSignedHex2sComplement, formatDeviceResponse, formatStrToMac } from "../utils/helpers";

const initialPayload: DeviceConfigurationPayload = {
  vendor_id: "",
  product_id: 0,
  customer_product_model_id: 0,
  speaker_position: "",
  serial_number: "",
  region: null,
};

const SpeakerPositions = ["left", "right"];

export interface Sequence {
  id: string | number;
  title: string;
  status: COMMAND_STATUS;
  deviceResponse?: string;
  resultType?: CustomCommandResultType;
  moveOn?: boolean;
  set_value?: string | null;
  allowUpdate?: boolean;
  passing_values?: PassingValue[];
  min_value?: number | null;
  max_value?: number | null;
}

interface UseSequenceProps {
  deviceConfigOptionsEnabled?: boolean;
  setError: Dispatch<SetStateAction<string | null>>;
  callbackApiUrl: string;
  multiDevice?: boolean;
  maxDeviceScan?: number;
}

const typeGuardForSerial = (connectedPort: unknown): connectedPort is SerialDriver => {
  return (
    typeof connectedPort === "object" &&
    connectedPort !== null &&
    "communicationType" in connectedPort &&
    connectedPort?.communicationType === CommunicationType.SERIAL
  );
};

// export const useSequence = ({ deviceConfigOptionsEnabled = false, setError, callbackApiUrl, multiDevice = false }: UseSequenceProps) => {
export const useSequence = ({
  deviceConfigOptionsEnabled = false,
  setError,
  callbackApiUrl,
  multiDevice = false,
  maxDeviceScan,
}: UseSequenceProps) => {
  const appContext = useContext(AppContext);

  const deviceConnection = useConnection({
    onDisconnect: () => {
      updateStatus(COMMAND_STATUS.InProgress, COMMAND_STATUS.Failed);
    },
  });

  const [seqId, setSeqId] = useState<string | number | null>(null);
  const [seqTitle, setSeqTitle] = useState("");
  const [sequence, setSequence] = useState<Sequence[] | null>(null);
  const [scannedMac, setScannedMac] = useState("");
  const [macAddresses, setMacAddresses] = useState<string[]>([]);
  const [deviceType, setDeviceType] = useState("");
  const [deviceConfigurationPayload, setDeviceConfigurationPayload] = useState(initialPayload);
  const [logs, setLogs] = useState<Log[]>([]);
  const [devConfigEnabled, setDevConfigEnabled] = useState(deviceConfigOptionsEnabled);
  const [isRegionEnabled, setIsRegionEnabled] = useState(true);
  const [isSpeakerPositionEnabled, setIsSpeakerPositionEnabled] = useState(true);
  const [showOverwriteModel, setShowOverwriteModal] = useState(false);
  const [overwriteMac, setOverwriteMac] = useState(false);
  const [overwriteCal, setOverwriteCal] = useState(false);
  const [skipDeviceProvisioning, setSkipDeviceProvisioning] = useState(false);
  const [changeAsscModeInRemote, setChangeAsscModeInRemote] = useState(false);

  const { context: customerContext } = useCustomerContext();

  const {
    data: beProductList,
    loading: isListLoading,
    run: fetchProductInfo,
  } = useFetch<GetProductListResponse>({
    url: getProductsList,
  });

  // List of all customers
  const customersList = useMemo(() => {
    const list = beProductList?.data?.list?.map((item) => ({ label: item.customer_name, value: item.vendor_id }));
    list?.unshift({ label: "Select Customer", value: "" });
    return list;
  }, [beProductList]);

  // List of all products based on selected customer
  const productList = useMemo(() => {
    const list = beProductList?.data?.list
      ?.find((item) => item.vendor_id === deviceConfigurationPayload.vendor_id)
      ?.customer_products.filter((item) => item.is_enable)
      .map((item) => ({ label: item.product_name, value: item.product_id }));

    list?.unshift({ label: "Select Product", value: -1 });
    return list;
  }, [beProductList, deviceConfigurationPayload]);

  // List of all models based on selected customer and product
  const modelList = useMemo(() => {
    const list = beProductList?.data?.list
      ?.find((item) => item.vendor_id === deviceConfigurationPayload.vendor_id)
      ?.customer_products.find((item) => item.product_id === +deviceConfigurationPayload.product_id)
      ?.customer_product_models.filter((item) => item.is_enable)
      .map((item) => ({
        label: `${item.model_id} - ${item.use_case_info?.use_case}`,
        value: item.customer_product_model_id,
        use_case_id: item.use_case_info?.use_case_id,
      }));

    list?.unshift({ label: "Select Model", value: -1, use_case_id: 0 });
    return list;
  }, [beProductList, productList, deviceConfigurationPayload]);

  const regionList = useMemo(() => {
    const customerProduct = beProductList?.data?.list
      ?.find((item) => item.vendor_id === deviceConfigurationPayload.vendor_id)
      ?.customer_products.find((item) => item.product_id === +deviceConfigurationPayload.product_id);

    const list = customerProduct?.regions?.map((item) => ({ label: item.region_details.region_name, value: item.region_code })) ?? [];

    if (customerProduct?.other_region) {
      list?.push({ label: `OTHER (${customerProduct.other_region})`, value: customerProduct.other_region });
    }

    if (list?.length === 0) {
      list.push({ label: "USA", value: USA_REGION_CODE });
    }

    return list;
  }, [beProductList, productList, deviceConfigurationPayload]);

  const isSequenceRunning = useMemo(() => sequence?.findIndex((item) => item.status === COMMAND_STATUS.InProgress) !== -1, [sequence]);

  /**
   * Updates status to display on the UI
   *
   * @param {COMMAND_STATUS} oldStatus - old status of the command item
   * @param {COMMAND_STATUS} newStatus - new status for the command item to be displayed on UI
   */
  const updateStatus = useCallback((oldStatus: COMMAND_STATUS, newStatus: COMMAND_STATUS) => {
    setSequence((prev) => {
      const index = prev?.findIndex((item) => item.status === oldStatus);

      if (prev) {
        return prev.map((item, i) => {
          if (index === i) {
            return { ...item, status: newStatus };
          } else {
            return item;
          }
        });
      }
      return prev;
    });
  }, []);

  /**
   * Use this function to set all sequences to aborted
   * Abort status is used to indicate failure in any of the command will cause sequence to stop completely
   */
  const abortAll = useCallback(() => {
    setSequence((prev) => {
      if (prev) {
        return prev.map((item) => ({ ...item, ...(item.status === COMMAND_STATUS.Pending && { status: COMMAND_STATUS.Aborted }) }));
      }
      return prev;
    });
  }, []);

  /**
   * Use this function to add device response to the sequence
   * This adds response to the command item which is in progress before calling the API
   * @param message - Device response
   */
  const setSequenceMessage = useCallback((message: string) => {
    setSequence((prev) => {
      if (prev) {
        return prev?.map((item) => {
          if (item?.status === COMMAND_STATUS.InProgress) {
            return { ...item, deviceResponse: message };
          } else {
            return item;
          }
        });
      }
      return prev;
    });
  }, []);

  /**
   * Handler to send device response to APP via API
   */
  const sendDeviceResponse = useCallback(
    async (payload: SendDeviceResponsePayload) => {
      if (!sequence) return;

      // Called when background process on the APP is successful and it responses with HTTP success code (200)
      const onSuccess = ({ data: response }: AxiosResponse<GetNextOperationResponse>) => {
        let newStatus: COMMAND_STATUS | null = null;

        // Change status based on response from API
        // Changing success and failed as per API response. In default case keeping it as in progress.
        // Default case will be hit when status is continue
        switch (response?.data?.status) {
          case COMMAND_RESPONSE_STATUS.Success:
            newStatus = COMMAND_STATUS.Success;
            break;

          case COMMAND_RESPONSE_STATUS.Failed:
            newStatus = COMMAND_STATUS.Failed;
            break;

          default:
            newStatus = COMMAND_STATUS.InProgress;
        }

        updateStatus(COMMAND_STATUS.InProgress, newStatus);

        appContext?.setSessionId(response?.data?.sessionId ?? "");

        // If API sends next command to execute, we recursively call runCommand until sequence is finished
        if (response?.data?.skipSubSeq === true) {
          updateStatus(COMMAND_STATUS.Pending, COMMAND_STATUS.Skipped);
          sendDeviceResponse({ deviceResponse: "skipped", wisaProduct: deviceType });
        } else if (response?.data?.command || response?.data?.fakeResponse) {
          if (response?.data?.status !== COMMAND_RESPONSE_STATUS.Continue) {
            updateStatus(COMMAND_STATUS.Pending, COMMAND_STATUS.InProgress);
          }

          // if we get fake response from API, we call sendDeviceResponse after 1 second
          if (response?.data?.fakeResponse) {
            setSequenceMessage(response?.data?.fakeResponse);

            setTimeout(() => {
              sendDeviceResponse({ deviceResponse: response?.data?.fakeResponse ?? "", wisaProduct: deviceType });
            }, 1000);
          }
          // in case of command we call runCommand
          else if (response?.data?.command) {
            if (response?.data?.command?.length === 0) {
              updateStatus(COMMAND_STATUS.InProgress, COMMAND_STATUS.Skipped);
              sendDeviceResponse({ deviceResponse: "skipped", wisaProduct: deviceType });
            } else {
              runCommand(response?.data?.command, response?.data?.commandName, response?.data?.set);
            }
          }
        } else if (response?.data?.customCommand) {
          if (response?.data?.status !== COMMAND_RESPONSE_STATUS.Continue) {
            updateStatus(COMMAND_STATUS.Pending, COMMAND_STATUS.InProgress);
          }
        } else if (response?.data?.skipNext === true) {
          updateStatus(COMMAND_STATUS.Pending, COMMAND_STATUS.Skipped);
          sendDeviceResponse({ deviceResponse: "", wisaProduct: deviceType });
        } else {
          // If sequence is finished, we set sequence to null
          setDeviceConfigurationPayload({
            vendor_id: customerContext.vendorId ?? "",
            product_id: customerContext.productId ?? 0,
            customer_product_model_id: customerContext.customerProductModelId ?? 0,
            speaker_position: "",
            serial_number: "",
            region: null,
          });
          setMacAddresses([]);
        }
      };

      // When error is thrown from API with 400 status code we are called with Error handler
      // setting sequence to failed when error is thrown
      const onError = () => {
        updateStatus(COMMAND_STATUS.InProgress, COMMAND_STATUS.Failed);
        abortAll();
      };

      http.makePostRequest<GetNextOperationResponse>(callbackApiUrl, onSuccess, onError, payload);
    },
    [sequence, customerContext, deviceType, appContext]
  );

  /**
   * Use this function for executing commands on device
   * Calls sendDeviceResponse recursively to execute sequence
   * @param command - Array of commands to execute on device
   * @param type - Type of command (0 means GET, 1 means SET)
   */
  const runCommand = useCallback(
    async (command: (string | number)[][], commandName: string, type?: number) => {
      if (!appContext?.connectedPort) return;

      const runSequence = appContext?.connectedPort?.runSequence;
      const deviceResponse = await runSequence(command, true, type);

      const trimmedDeviceResponse = deviceResponse?.filter((item) => item !== 0x00 && item !== 0xff);

      let logString = "";

      // Customizable log for hex commands
      // If we need to show hex values in reverse order, we can add reverse: true in HEX_LOG_COMMAND_NAMES
      const isHexLog = HEX_LOG_COMMAND_NAMES.find((item) => item.name === commandName);
      if (isHexLog) {
        // reverse the array if reverse is true
        const arr = isHexLog.reverse ? trimmedDeviceResponse?.reverse() : trimmedDeviceResponse;
        logString = formatDeviceResponse(arr, "0x");
      } else {
        logString = new TextDecoder().decode(deviceResponse);
      }

      if (commandName === "GET_TX_I2S_FORMAT" && deviceResponse[0] !== 0xfd) {
        const txI2sFormat8BitBuffer = new Uint8Array(deviceResponse);
        const pcmEnum = +txI2sFormat8BitBuffer[0];
        const pcmConfigLabel = PCM_FORMAT_ENTERPRISE_LABEL_MAP[pcmEnum as keyof typeof PCM_FORMAT_ENTERPRISE_LABEL_MAP];
        const bitsPerFrameSampleEnum = +txI2sFormat8BitBuffer[1];
        const bitsPerFrameSampleConfigLabel =
          BITS_PER_FRAME_SAMPLE_ENTERPRISE_LABEL_MAP[bitsPerFrameSampleEnum as keyof typeof BITS_PER_FRAME_SAMPLE_ENTERPRISE_LABEL_MAP];
        const sampleRateKey = +txI2sFormat8BitBuffer[2];
        const sampleRateConfigLabel = SAMPLE_RATE_ENTERPRISE_LABEL_MAP[sampleRateKey as keyof typeof SAMPLE_RATE_ENTERPRISE_LABEL_MAP];
        logString = `${pcmConfigLabel}, ${bitsPerFrameSampleConfigLabel}, ${sampleRateConfigLabel}`;
      }

      let decimalToString = "";
      deviceResponse?.forEach((item) => {
        decimalToString += item.toString(16).padStart(2, "0");
      });

      const isMacResponse = commandName === "GET_EFUSE_MAC";
      const logValue = deviceResponse.length === 1 ? `0x${deviceResponse[0].toString(16).padStart(2, "0")}` : logString;

      const isEFuseCalGet = commandName === "GET_EFUSE_CAL_DATA";
      const isGetChallenge = commandName === "GET_CHALLENGE";
      const isGetBurnCalDfs = commandName === "GET_BURN_CAL_DFS";

      const isRssi = commandName === "GET_RSSI";

      let finalResponse = new TextDecoder().decode(trimmedDeviceResponse);
      let finalLog = logValue;

      let twosComplementedValueForTx = "";
      if (isMacResponse) {
        const hexFormat = formatDeviceResponse(deviceResponse, "0x");
        const formattedMacAddress = deviceResponse.length === 1 ? hexFormat : formatStrToMac(decimalToString);

        finalLog = formattedMacAddress;
        finalResponse = formattedMacAddress;
        if (deviceResponse[0] === 0xfd) {
          toast.error("Device cannot be calibrated, aborting");
        }
      } else if (isEFuseCalGet) {
        finalLog = decimalToString;
        finalResponse = decimalToString;
      } else if (isGetBurnCalDfs) {
        finalLog = decimalToString;
        finalResponse = decimalToString;
      } else if (isGetChallenge) {
        finalResponse = decimalToString;
        finalLog = deviceResponse.length === 260 ? "0x01" : "0xFF";
      } else if (isRssi) {
        if (deviceResponse[0]) {
          twosComplementedValueForTx = `-${findSignedHex2sComplement(deviceResponse[0])}`;
          finalLog = `Tx: ${twosComplementedValueForTx}`;
        }

        if (deviceResponse[4]) {
          finalLog = `${finalLog}, Rx: -${findSignedHex2sComplement(deviceResponse[4])}`;
        }
      }

      if (deviceResponse) {
        setLogs((prev) => [
          ...prev,
          {
            commandName,
            value: finalLog,
          },
        ]);

        if (deviceResponse.length === 1) {
          sendDeviceResponse({ deviceResponse: deviceResponse[0].toString(), wisaProduct: deviceType });
          return;
        }

        if (isRssi) {
          sendDeviceResponse({ deviceResponse: twosComplementedValueForTx, wisaProduct: deviceType });
        } else {
          sendDeviceResponse({ deviceResponse: isHexLog ? logString : finalResponse, wisaProduct: deviceType });
        }
      }
    },
    [sequence, deviceType, logs, appContext]
  );

  /**
   * Validation for scanned values for device configuration payload
   */
  const validateConfigurationPayload = useCallback(
    (payload?: typeof deviceConfigurationPayload) => {
      const values = payload || deviceConfigurationPayload;

      // If value is not in the list of customers, return false
      if (customersList?.some((item) => item.value === values.vendor_id) === false) {
        setError("Please select a valid customer");
        return false;
      }

      // If value is not in the list of products, return false
      if (productList?.some((item) => item.value === +values.product_id) === false) {
        setError("Please select a valid product");
        return false;
      }

      // If value is not in the list of models, return false
      if (modelList?.some((item) => item.value === +values.customer_product_model_id) === false) {
        setError("Please select a valid model");
        return false;
      }

      // If value is not in the list of speaker positions, return false
      if (values.speaker_position && SpeakerPositions.includes(values.speaker_position) === false) {
        setError("Please select a valid speaker position");
        return false;
      }

      // If serial number is not in the range of 1 to 16 characters, return false
      if (values.serial_number.length > 16) {
        setError(`Please enter/scan a valid serial number. (Scanned/Input value: ${values.serial_number})`);
        updatePayloadValue("serial_number", "");
        return false;
      }

      setError("");
      return true;
    },
    [deviceConfigurationPayload, customersList, productList, modelList]
  );

  const callScanAPI = useCallback(
    ({ customSequence = sequence, macAddress = scannedMac, apiURL = postScannedMAC, scannedMacAddresses = macAddresses }) => {
      if (!sequence) return;

      setShowOverwriteModal(false);
      setLogs([]);

      // Set first sequence item to in progress mode
      setSequence(
        sequence?.map((item, index) => {
          if (index === 0) {
            return { ...item, deviceResponse: undefined, status: COMMAND_STATUS.InProgress };
          } else {
            return { ...item, deviceResponse: undefined, status: COMMAND_STATUS.Pending };
          }
        })
      );

      const customer_product_id = beProductList?.data?.list
        ?.find((item) => item.vendor_id === deviceConfigurationPayload.vendor_id)
        ?.customer_products.find((item) => item.product_id === +deviceConfigurationPayload.product_id)?.customer_product_id;

      const preparedContext = {
        vendor_id: deviceConfigurationPayload?.vendor_id,
        customer_product_id: customer_product_id ? customer_product_id : customerContext.customerProductId,
        serial_number: deviceConfigurationPayload?.serial_number,
        customer_product_model_id: deviceConfigurationPayload.customer_product_model_id,
        speaker_position: deviceConfigurationPayload?.speaker_position ? deviceConfigurationPayload?.speaker_position : null,
        region: deviceConfigurationPayload?.region ? deviceConfigurationPayload?.region : null,
      };

      const contextDetails =
        preparedContext?.vendor_id && preparedContext.customer_product_id && preparedContext?.customer_product_model_id
          ? preparedContext
          : null;

      let payload;

      if (seqId === "DEVICE_CALIBRATION") {
        payload = {
          contextDetails: contextDetails,
          overrideEFuseMac: overwriteMac,
          overrideEFuseCalData: overwriteCal,
          communicationType: appContext?.connectedPort?.communicationType,
          skipDeviceProvisioning,
        };
      } else if (seqId === "REMOTE_COMMANDS") {
        payload = {
          macAddresses: scannedMacAddresses,
          sequence: seqId,
          contextDetails: contextDetails,
          wisaProduct: deviceType,
          changeAsscModeInRemote: changeAsscModeInRemote,
          communicationType: appContext?.connectedPort?.communicationType,
        };
      } else if ((seqId === "MODULE_PROGRAMMING" || seqId === "DEVICE_CONFIGURATION") && multiDevice) {
        payload = {
          macAddress: scannedMacAddresses[0],
          remoteMacAddress: scannedMacAddresses[1],
          sequence: seqId,
          contextDetails: contextDetails,
          wisaProduct: deviceType,
          communicationType: appContext?.connectedPort?.communicationType,
        };
      } else if (typeof seqId === "number" && multiDevice) {
        payload = {
          macAddress: scannedMacAddresses[0],
          remoteMacAddress: scannedMacAddresses[1],
          sequence: seqId,
          contextDetails: contextDetails,
          customSeqOrder: typeof seqId === "number" ? customSequence?.map((item) => item.set_value) : null,
          wisaProduct: deviceType,
          communicationType: appContext?.connectedPort?.communicationType,
        };
      } else {
        payload = {
          macAddress,
          sequence: seqId,
          contextDetails: contextDetails,
          customSeqOrder: typeof seqId === "number" ? customSequence?.map((item) => item.set_value) : null,
          wisaProduct: deviceType,
          communicationType: appContext?.connectedPort?.communicationType,
          remoteMacAddress: null,
        };
      }

      const onSuccess = ({ data: response }: AxiosResponse<PostScannedMacResponse>) => {
        if (response.status) {
          // Store session id
          appContext?.setSessionId(response?.data?.sessionId);

          if (response.data.sequence) {
            setSequence(
              response.data.sequence.map((item, index) => ({
                id: item.wisa_command_sequence,
                title: item.wisa_sub_seq.command_name,
                status: index ? COMMAND_STATUS.Pending : COMMAND_STATUS.InProgress,
                moveOn: item.wisa_sub_seq.move_on,
              }))
            );
          }

          // Start with running command on the device
          runCommand(response?.data?.command, response?.data?.commandName);
        } else {
          updateStatus(COMMAND_STATUS.InProgress, COMMAND_STATUS.Failed);
        }
      };

      const onError = () => {
        updateStatus(COMMAND_STATUS.InProgress, COMMAND_STATUS.Failed);
      };

      // Send scanned MAC to APP
      http.makePostRequest<PostScannedMacResponse>(apiURL, onSuccess, onError, payload);
    },
    [
      scannedMac,
      sequence,
      deviceConnection.connected,
      isSequenceRunning,
      devConfigEnabled,
      deviceConfigurationPayload,
      beProductList,
      customerContext,
      seqId,
      deviceType,
      overwriteMac,
      overwriteCal,
      macAddresses,
      changeAsscModeInRemote,
      appContext,
      multiDevice,
      skipDeviceProvisioning,
    ]
  );

  /**
   * Event listener for the scan event
   * Called when scanner is finished with scanning
   */
  const handleScan = useCallback(
    async (e: CustomEvent) => {
      if (devConfigEnabled && !validateConfigurationPayload()) {
        return;
      }

      if (!deviceType) {
        toast.error("Please select a WiSA product");
        return;
      }

      const macAddress = formatMAC(e.detail.slice(e.detail.length - 12, e.detail.length));

      // If scanned value is not a valid MAC address, stop execution
      if (!macAddress.match(MAC_ADDRESS_REGEX)) {
        return;
      }

      setScannedMac(macAddress);

      if (isSequenceRunning) {
        toast.warn("Please wait for the current sequence to finish");
        return;
      }

      if (!sequence) return;

      if (sequence.some((item) => item.allowUpdate)) {
        setShowOverwriteModal(true);
        return;
      }

      callScanAPI({ macAddress });
    },
    [
      scannedMac,
      sequence,
      deviceConnection.connected,
      isSequenceRunning,
      devConfigEnabled,
      deviceConfigurationPayload,
      beProductList,
      customerContext,
      seqId,
      showOverwriteModel,
      callScanAPI,
    ]
  );

  /**
   * Handle scan multiple MAC addresses
   * This will be used in case of remote commands execution
   */
  const handleScanMultiple = useCallback(
    (e: CustomEvent) => {
      if (devConfigEnabled && !validateConfigurationPayload()) {
        return;
      }

      if (!deviceType) {
        toast.error("Please select a WiSA product");
        return;
      }

      const macAddress = formatMAC(e.detail.slice(e.detail.length - 12, e.detail.length));

      // If scanned value is not a valid MAC address, stop execution
      if (!macAddress.match(MAC_ADDRESS_REGEX)) {
        return;
      }

      const scannedMacAddresses: string[] =
        macAddresses?.length === 0 || macAddresses.length === maxDeviceScan ? [macAddress] : [...macAddresses, macAddress];
      setMacAddresses(scannedMacAddresses);

      if (maxDeviceScan && scannedMacAddresses.length >= maxDeviceScan) {
        callScanAPI({ scannedMacAddresses });
      }
    },
    [devConfigEnabled, validateConfigurationPayload, deviceType, macAddresses]
  );

  const updatePayloadValue = useCallback(
    (fieldName: keyof DeviceConfigurationPayload, value: string | number, sideEffectFields?: (keyof DeviceConfigurationPayload)[]) => {
      const values = { ...deviceConfigurationPayload };

      const sideEffectUpdatedValues = sideEffectFields ? sideEffectFields.map((field) => ({ [field]: initialPayload[field] })) : [];
      Object.assign(values, { [fieldName]: value, ...sideEffectUpdatedValues.reduce((acc, cur) => ({ ...acc, ...cur }), {}) });

      setDeviceConfigurationPayload(values);
    },
    [deviceConfigurationPayload]
  );

  /**
   * Scan handler for configuration QR scanning
   * This function is handler for scanning when we are in device configuration
   */
  const handleScanDeviceConfiguration = useCallback(
    (e: CustomEvent) => {
      const keys = Object.keys(deviceConfigurationPayload);
      const values = Object.values(deviceConfigurationPayload);

      // if all fields are already filled by scan or manual entry run MAC scan
      if (values.every((item) => item !== 0 && item !== "")) {
        if (multiDevice) {
          handleScanMultiple(e);
          return;
        }

        handleScan(e);
        return;
      }

      // Checking over all fields of payload, writing in empty fields one by one
      for (const key of keys) {
        const k = key as keyof typeof deviceConfigurationPayload;

        // if field is empty or has 0 fill new value to it
        if (deviceConfigurationPayload[k] === 0 || deviceConfigurationPayload[k] === "") {
          const newValues = { ...deviceConfigurationPayload, [key]: e.detail };

          if (validateConfigurationPayload(newValues)) {
            updatePayloadValue(k, e.detail);
            break;
          }
        }
      }
    },
    [deviceConfigurationPayload, handleScan, beProductList, modelList, multiDevice]
  );

  const handleClickAssignMac = useCallback(() => {
    callScanAPI({ apiURL: runDeviceCalibration });
  }, [callScanAPI, overwriteMac]);

  // this function is responsible for running serial programming API
  // It calls the serial programming GET API to get command for updating device using UART
  const runSerialProgramming = useCallback(() => {
    setLogs([]);

    const onSuccess = async ({ data: response }: AxiosResponse<PostScannedMacResponse>) => {
      if (response.status) {
        const localPort = appContext?.connectedPort;

        // Start the sub sequence
        updateStatus(COMMAND_STATUS.Pending, COMMAND_STATUS.InProgress);
        // Start with running command on the device
        if (typeGuardForSerial(localPort)) {
          const deviceResponse = await localPort?.runSequence(response?.data?.command);

          // This is when device response is successfully with valid ACK
          if (deviceResponse.length) {
            // If correct ACK is returned from device make it success otherwise fail
            if (deviceResponse[0] === localPort.ACK) {
              updateStatus(COMMAND_STATUS.InProgress, COMMAND_STATUS.Success);
            } else {
              updateStatus(COMMAND_STATUS.InProgress, COMMAND_STATUS.Failed);
            }

            setLogs([
              {
                commandName: response?.data?.commandName,
                value: formatDeviceResponse(deviceResponse, "0x"),
              },
            ]);
          } else {
            // In any other case fail the sequence
            updateStatus(COMMAND_STATUS.InProgress, COMMAND_STATUS.Failed);
          }
        }
      } else {
        updateStatus(COMMAND_STATUS.InProgress, COMMAND_STATUS.Failed);
      }
    };

    // When error is thrown from API with 400 status code we are called with Error handler
    // setting sequence to failed when error is thrown
    const onError = () => {
      updateStatus(COMMAND_STATUS.InProgress, COMMAND_STATUS.Failed);
      abortAll();
    };

    http.makeGetRequest<PostScannedMacResponse>(getSerialProgrammingCommand, onSuccess, onError);
  }, [appContext?.connectedPort]);

  useEffect(() => {
    if (appContext && deviceConnection.connected) {
      // When we have remote commands we need to allow scanning multiple device mac addresses
      if (!multiDevice) {
        // For device configuration page we need to use special scan handler to first scan Customer, Product, Model info
        // For other sequences we are using only MAC
        if (!devConfigEnabled) {
          watchScanner(appContext, handleScan);
        } else {
          watchScanner(appContext, handleScanDeviceConfiguration);
        }
      } else {
        if (!devConfigEnabled) {
          watchScanner(appContext, handleScanMultiple);
        } else {
          watchScanner(appContext, handleScanDeviceConfiguration);
        }
      }
    }

    return () => {
      if (!multiDevice) {
        if (!devConfigEnabled) {
          removeScanner(handleScan);
        } else {
          removeScanner(handleScanDeviceConfiguration);
        }
      } else {
        removeScanner(handleScanMultiple);
      }
    };
  }, [
    scannedMac,
    sequence,
    appContext,
    deviceConnection.connected,
    devConfigEnabled,
    deviceConfigurationPayload,
    deviceType,
    macAddresses,
    multiDevice,
  ]);

  // This effect manages speaker position field visibility depending on selected model
  useEffect(() => {
    const selectedUseCase = modelList?.find((item) => item.value === deviceConfigurationPayload.customer_product_model_id)?.use_case_id;

    // When speaker position field is in payload
    if (selectedUseCase && deviceConfigurationPayload.speaker_position !== undefined) {
      const newValues = { ...deviceConfigurationPayload };

      // If selected model's use case is not in required_speaker_position array, delete speaker position field
      if (!beProductList?.data.required_speaker_position.includes(selectedUseCase) || !isSpeakerPositionEnabled) {
        delete newValues.speaker_position;
        setDeviceConfigurationPayload(newValues);
      }
    }

    // When speaker position field is not in payload
    else if (deviceConfigurationPayload.speaker_position === undefined) {
      // If selected model's use case is in required_speaker_position array, add speaker position field
      if (selectedUseCase && beProductList?.data.required_speaker_position.includes(selectedUseCase)) {
        // Creating new object with speaker position field
        const newValues = {
          vendor_id: deviceConfigurationPayload.vendor_id,
          product_id: deviceConfigurationPayload.product_id,
          customer_product_model_id: deviceConfigurationPayload.customer_product_model_id,
          speaker_position: "",
          serial_number: deviceConfigurationPayload.serial_number,
        };

        setDeviceConfigurationPayload(newValues);
      }
    }
  }, [deviceConfigurationPayload, modelList, beProductList, isSpeakerPositionEnabled]);

  useEffect(() => {
    setDeviceConfigurationPayload({
      vendor_id:
        appContext?.user && !appContext.userHasOEMAccess && !customerContext.vendorId
          ? appContext.user.vendor_id
          : customerContext.vendorId ?? "",
      product_id: customerContext.productId ?? 0,
      customer_product_model_id: customerContext.customerProductModelId ?? 0,
      speaker_position: "",
      serial_number: "",
      region: null,
    });
  }, [customerContext, appContext?.user]);

  useEffect(() => {
    const productList = beProductList?.data.list.find((item) => item.vendor_id === customerContext.vendorId)?.customer_products;
    const productType = productList?.find((item) => item.product_id === customerContext.productId)?.product_type;

    if (productType) {
      setDeviceType(productType);
    }
  }, [customerContext, beProductList]);

  // Clear scan data on page load
  useEffect(() => {
    if (!isSequenceRunning) appContext?.setScan([]);
  }, [isSequenceRunning]);

  // Removing autofocus from all links when page loads
  // Focus on link is not allowing scan events to be triggered from the parent
  useEffect(() => {
    const allLinks = document.querySelectorAll("a");
    allLinks.forEach((link) => {
      link.blur();
    });
  }, []);

  useEffect(() => {
    fetchProductInfo();
  }, []);

  usePrompt(userConfirmationMessage, isSequenceRunning);

  return {
    seqTitle,
    setSeqTitle,
    sequence,
    setSequence,
    scannedMac,
    deviceType,
    isSequenceRunning,
    configItemsLoading: isListLoading,
    customersList,
    productList,
    modelList,
    updateStatus,
    logs,
    deviceConnection,
    setSeqId,
    updatePayloadValue,
    setDeviceConfigurationPayload,
    deviceConfigurationPayload,
    sendDeviceResponse,
    devConfigEnabled,
    setDevConfigEnabled,
    regionList,
    setDeviceType,
    isRegionEnabled,
    setIsRegionEnabled,
    isSpeakerPositionEnabled,
    setIsSpeakerPositionEnabled,
    showOverwriteModel,
    setShowOverwriteModal,
    callScanAPI,
    handleClickAssignMac,
    overwriteMac,
    setOverwriteMac,
    overwriteCal,
    setOverwriteCal,
    skipDeviceProvisioning,
    setSkipDeviceProvisioning,
    macAddresses,
    validateConfigurationPayload,
    changeAsscModeInRemote,
    setChangeAsscModeInRemote,
    beProductList,
    runSerialProgramming,
  };
};
