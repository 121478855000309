import * as XLSX from "xlsx";

import styles from "./styles.module.scss";
import { useFetch } from "../../hooks/useFetch";
import { ReportsResponse } from "../../interfaces/be.interfaces";
import { getCertificationReports } from "../../api/config";
import { useCallback } from "react";
import AccessGuard from "../../components/Guards/AccessGuard";
import { PERMISSIONS } from "../../utils/constants";

const CertificationReports = () => {
  // Hook to get the list of the customer
  const { data } = useFetch<ReportsResponse>({ url: getCertificationReports, runOnMount: true });

  const handleExport = useCallback(() => {
    if (data?.data?.reports) {
      const ws = XLSX.utils.json_to_sheet(data?.data?.reports);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Reports");

      const date = new Date();
      const currentTimestamp = `${date.getDate()}_${date.getMonth()}_${date.getFullYear()}`;

      XLSX.writeFile(wb, `Certification_Reports_${currentTimestamp}.xlsx`);
    }
  }, [data?.data?.reports]);

  return (
    <>
      <div className={styles.pageHeader}>
        <p>Certification Stats</p>
      </div>
      <div className={styles.statsContainer}>
        <div className={styles.stats}>
          <div>
            <p className={styles.statTitle}>Total Completed Certification Test</p>
            <p className={styles.count}>{data?.data?.stats?.totalCertifications?.total}</p>
          </div>
          <div className={styles.miniStats}>
            <p>RX: {data?.data?.stats?.totalCertifications?.rx}</p>
            <p>TX: {data?.data?.stats?.totalCertifications?.tx}</p>
          </div>
        </div>
        <div className={styles.stats}>
          <div>
            <p className={styles.statTitle}>Pass Certifications</p>
            <p className={styles.count}>{data?.data?.stats?.passCertifications?.total}</p>
          </div>
          <div className={styles.miniStats}>
            <p>RX: {data?.data?.stats?.passCertifications?.rx}</p>
            <p>TX: {data?.data?.stats?.passCertifications?.tx}</p>
          </div>
        </div>
        <div className={styles.stats}>
          <div>
            <p className={styles.statTitle}>Fail Certifications</p>
            <p className={styles.count}>{data?.data?.stats?.failCertifications?.total}</p>
          </div>
          <div className={styles.miniStats}>
            <p>RX: {data?.data?.stats?.failCertifications?.rx}</p>
            <p>TX: {data?.data?.stats?.failCertifications?.tx}</p>
          </div>
        </div>
        <div className={styles.stats}>
          <div>
            <p className={styles.statTitle}>In Progress Certifications</p>
            <p className={styles.count}>{data?.data?.stats?.inProgressCertifications?.total}</p>
          </div>
          <div className={styles.miniStats}>
            <p>RX: {data?.data?.stats?.inProgressCertifications?.rx}</p>
            <p>TX: {data?.data?.stats?.inProgressCertifications?.tx}</p>
          </div>
        </div>
      </div>
      <AccessGuard permission={PERMISSIONS.CERTIFICATION_REPORTS_EXPORT}>
        <div className={styles.pageHeader}>
          <p>Reports</p>
          <button onClick={handleExport}>Export Report</button>
        </div>
      </AccessGuard>
      <div className={styles.tableResponsive}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.center}>Customer ID</th>
              <th>Customer Name</th>
              <th>Customer Dev Phase</th>
              <th className={styles.center}>Total Tests</th>
              <th className={styles.center}>Certification Counts</th>
              <th className={styles.center}>RF Pass(%)</th>
              <th className={styles.center}>Audio Pass(%)</th>
              <th className={styles.center}>Command Pass(%)</th>
              <th className={styles.center}>Optional Pass(%)</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.reports?.map((value, index) => (
              <tr key={index} className={styles.bodyRow}>
                <td className={styles.center}>{value.customer_id}</td>
                <td>{value.customer_name}</td>
                <td>{value.customer_dev_phase}</td>
                <td className={styles.center}>{value.dev_phase_tests}</td>
                <td className={styles.center}>{value.cert_count}</td>
                <td className={styles.center}>{value.per_rf_pass}</td>
                <td className={styles.center}>{value.per_audio_pass}</td>
                <td className={styles.center}>{value.per_command_pass}</td>
                <td className={styles.center}>{value.per_optional_pass}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CertificationReports;
