// import { Dispatch, SetStateAction, useContext, useEffect, useState, useMemo, useCallback } from "react";
import { Dispatch, SetStateAction, useContext, useEffect, useState, useMemo, useCallback, ChangeEvent } from "react";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";
import CommandResponse from "../../components/CommandResponse";
import ConnectDialog from "../../components/ConnectDialog";
import { COMMAND_STATUS, STATUS_CLASS_MAPPING } from "../../utils/constants";
import { useSequence } from "../../hooks/useSequence";
import { AppContext } from "../../context/AppContext";
import { Sequence, GetWisaProductsResponse, WisaSequences } from "../../interfaces/be.interfaces";
import Spinner from "../../components/Spinner";
import { deviceCalibrationResponse, deviceResponse, getWisaProducts } from "../../api/config";
import Modal from "../../components/Modal/Modal";
import { Icons } from "../../../assets/images";
import { useFetch, UseFetchProps } from "../../hooks/useFetch";
import ModuleProgrammingSpeakerTesting from "./ModuleProgrammingSpeakerTesting";
import ModuleProgrammingSystemTesting from "./ModuleProgrammingSystemTesting";
import ModuleProgrammingDeviceCalibration from "./ModuleProgrammingDeviceCalibration";
import ModuleProgrammingDeviceConfiguration from "./ModuleProgrammingDeviceConfiguration";
import ModuleProgrammingFirmwareProgramming from "./ModuleProgrammingFirmwareProgramming";
import ModuleProgrammingRemoteCommands from "./ModuleProgrammingRemoteCommands";
import { usePermission } from "../../hooks/usePermission";
import { PERMISSIONS } from "../../utils/constants";

interface OnErrorResponse {
  message: string;
}
interface StatusInfoProps {
  infoModelOpen: boolean;
  setInfoModelOpen: Dispatch<SetStateAction<boolean>>;
}

export const StatusInfo = ({ infoModelOpen, setInfoModelOpen }: StatusInfoProps) => {
  return (
    <Modal isModalOpen={infoModelOpen} onModalClose={() => setInfoModelOpen(false)}>
      <div className={styles.howItWorksInfo}>
        <div className={styles.aboutStatus}>
          <span className={styles.pending}>Pending</span>
          <div>Command yet to execute.</div>
        </div>
        <div className={styles.aboutStatus}>
          <span className={styles.inProgress}>In Progress</span>
          <div>Command currently executing.</div>
        </div>
        <div className={styles.aboutStatus}>
          <span className={styles.failed}>Failed</span>
          <div>Command failed due to invalid or unexpected response from device.</div>
        </div>
        <div className={styles.aboutStatus}>
          <span className={styles.pending}>Aborted</span>
          <div>Command aborted because a prior command of the sequence failed.</div>
        </div>
        <div className={styles.aboutStatus}>
          <span className={styles.skipped}>Skipped</span>
          <div>We skip command&apos;s execution when command is for TX devices and current mode is RX</div>
        </div>
        <div className={styles.aboutStatus}>
          <span className={styles.icon}>
            <Icons.MoveOnIcon />
          </span>
          <div>Low priority command. The sequence will not be aborted on failure of this command.</div>
        </div>
      </div>
    </Modal>
  );
};

interface ModuleProgrammingProps {
  page: string;
}

const ModuleProgramming = ({ page }: ModuleProgrammingProps) => {
  const appContext = useContext(AppContext);
  const [error, setError] = useState<string | null>(null);
  const [infoModelOpen, setInfoModelOpen] = useState(false);
  const [isRemoteMacAvailable, setIsRemoteMacAvailable] = useState(false);

  const isMultiDevice = useMemo(() => {
    const list = [WisaSequences.REMOTE_COMMANDS];
    if (isRemoteMacAvailable) {
      list.push(WisaSequences.MODULE_PROGRAMMING);
      list.push(WisaSequences.DEVICE_CONFIGURATION);
    }

    return list.includes(page as WisaSequences);
  }, [isRemoteMacAvailable, page]);

  const {
    sequence,
    setSequence,
    seqTitle,
    setSeqTitle,
    scannedMac,
    deviceType,
    logs,
    deviceConnection: { connected, connecting, setConnected, setConnecting },
    setSeqId,
    configItemsLoading,
    deviceConfigurationPayload,
    setDeviceConfigurationPayload,
    customersList,
    changeAsscModeInRemote,
    setChangeAsscModeInRemote,
    productList,
    modelList,
    updatePayloadValue,
    regionList,
    isSequenceRunning,
    setDeviceType,
    isRegionEnabled,
    isSpeakerPositionEnabled,
    handleClickAssignMac,
    overwriteMac,
    setOverwriteMac,
    overwriteCal,
    setOverwriteCal,
    skipDeviceProvisioning,
    setSkipDeviceProvisioning,
    macAddresses,
    callScanAPI,
    validateConfigurationPayload,
    beProductList,
    runSerialProgramming,
  } = useSequence({
    setError,
    deviceConfigOptionsEnabled: [WisaSequences.DEVICE_CONFIGURATION, WisaSequences.REMOTE_COMMANDS].includes(page as WisaSequences),
    callbackApiUrl: page !== WisaSequences.DEVICE_CALIBRATION ? deviceResponse : deviceCalibrationResponse,
    multiDevice: isMultiDevice,
    maxDeviceScan: [WisaSequences.MODULE_PROGRAMMING, WisaSequences.DEVICE_CONFIGURATION].includes(page as WisaSequences) ? 2 : undefined,
  });

  const { checkPermission } = usePermission();
  const haveSkipDeviceProvisioningPermission = checkPermission(PERMISSIONS.SKIP_DEVICE_PROVISIONING);

  const onGetWisaProductsFailure = useCallback((error: AxiosError<OnErrorResponse, any> | undefined) => {
    toast.error("WiSA products failed to load. Try reloading the page.");
    console.log(`[GetWisaProductsFailure]: ${error?.message}`);
  }, []);

  const useFetchGetWisaProductsProps: UseFetchProps<GetWisaProductsResponse, OnErrorResponse> = useMemo(
    () => ({
      url: getWisaProducts,
      runOnMount: true,
      onError: onGetWisaProductsFailure,
    }),
    []
  );
  const { data: wisaProducts } = useFetch(useFetchGetWisaProductsProps);

  const handleChangeOverwriteCalData = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setOverwriteCal(event.target.checked);
    },
    [setOverwriteCal]
  );

  const handleChangeOverwriteMac = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setOverwriteMac(event.target.checked);
    },
    [setOverwriteMac]
  );

  const handleChangeSkipDeviceProvisioning = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSkipDeviceProvisioning(event.target.checked);
    },
    [setSkipDeviceProvisioning]
  );

  const handleChangeCustomerProduct = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      updatePayloadValue("product_id", +event.target.value);
      const selectedProduct = beProductList?.data?.list
        ?.find((vendor) => vendor.vendor_id === deviceConfigurationPayload.vendor_id)
        ?.customer_products.find((product) => product.product_id === +event.target.value);

      if (selectedProduct) {
        setDeviceType(selectedProduct.product_type);
      }
    },
    [updatePayloadValue, beProductList, deviceConfigurationPayload.vendor_id, setDeviceType]
  );

  const handleChangeModel = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      updatePayloadValue("customer_product_model_id", +event.target.value);
    },
    [updatePayloadValue]
  );

  const handleChangeVendor = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      updatePayloadValue("vendor_id", event.target.value, ["customer_product_model_id", "product_id"]);
      setDeviceType("");
    },
    [updatePayloadValue]
  );

  const handleChangeWisaProduct = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setDeviceType(event.target.value);
    },
    [setDeviceType]
  );

  const handleClickStartExecution = useCallback(() => {
    if (validateConfigurationPayload()) {
      callScanAPI({});
    }
  }, [validateConfigurationPayload, callScanAPI]);

  useEffect(() => {
    const pageData = appContext?.pageData;

    if (pageData) {
      // Taking page specific data depending on URL route
      let sequenceData: Sequence | null = pageData[0] ?? null;

      // if param is found in URL find data for that page from context
      if (page) {
        sequenceData = pageData?.find((o) => o.name.toLowerCase() === page.toLowerCase()) ?? null;
      }

      if (sequenceData) {
        const modifiedSequenceData = sequenceData.wisa_sequence_command_orders.map((item) => ({
          id: item.wisa_command_sequence,
          title: item.wisa_sub_seq.command_name,
          status: COMMAND_STATUS.Pending,
          moveOn: item.wisa_sub_seq.move_on,
        }));
        setSequence(modifiedSequenceData);
        setSeqTitle(sequenceData.description);
        setSeqId(sequenceData.name);
      } else {
        setError("No data found for this page");
      }
    }
  }, [page]);

  const deviceStatus = connected ? "Connected" : "Disconnected";

  if (configItemsLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.titleContainer}>
        <p>
          {seqTitle}
          <span onClick={() => setInfoModelOpen(true)}>&nbsp;&#9432;</span>
        </p>
        <div className={styles.statusContainer}>
          <div className={`${styles.statusDot} ${styles?.[deviceStatus]}`}></div>
          <span>{deviceStatus}</span>
        </div>
      </div>
      {error ? <div className="errorAlert">{error}</div> : null}
      <section className={`d-flex justify-content-between ${styles.moduleContainer}`}>
        <div className={`d-flex justify-content-between ${styles.informationContainer}`}>
          {
            {
              [WisaSequences.DEVICE_CONFIGURATION]: (
                <ModuleProgrammingDeviceConfiguration
                  deviceConfigurationPayload={deviceConfigurationPayload}
                  setDeviceConfigurationPayload={setDeviceConfigurationPayload}
                  customersList={customersList}
                  productList={productList}
                  modelList={modelList}
                  updatePayloadValue={updatePayloadValue}
                  regions={regionList ?? []}
                  isSequenceRunning={isSequenceRunning}
                  isRegionEnabled={isRegionEnabled}
                  isSpeakerPositionEnabled={isSpeakerPositionEnabled}
                  scannedMac={scannedMac}
                  showAsscModeCheckbox={false}
                  changeAsscModeInRemote={changeAsscModeInRemote}
                  setChangeAsscModeInRemote={setChangeAsscModeInRemote}
                  wisaProducts={wisaProducts?.data.list || []}
                  onChangeWisaProduct={handleChangeWisaProduct}
                  deviceType={deviceType}
                  beProductList={beProductList}
                  setDeviceType={setDeviceType}
                  isRemoteMacAvailable={isRemoteMacAvailable}
                  setIsRemoteMacAvailable={setIsRemoteMacAvailable}
                  macAddresses={macAddresses}
                />
              ),
              [WisaSequences.MODULE_PROGRAMMING]: (
                <ModuleProgrammingFirmwareProgramming
                  customerList={customersList || []}
                  deviceType={deviceType}
                  isSequenceRunning={isSequenceRunning}
                  modelList={modelList || []}
                  customer_product_model_id={deviceConfigurationPayload.customer_product_model_id}
                  onChangeCustomerProduct={handleChangeCustomerProduct}
                  onChangeModel={handleChangeModel}
                  onChangeVendor={handleChangeVendor}
                  onChangeWisaProduct={handleChangeWisaProduct}
                  productList={productList || []}
                  product_id={deviceConfigurationPayload.product_id}
                  macAddresses={macAddresses}
                  vendor_id={deviceConfigurationPayload.vendor_id}
                  wisaProducts={wisaProducts?.data.list || []}
                  isRemoteMacAvailable={isRemoteMacAvailable}
                  setIsRemoteMacAvailable={setIsRemoteMacAvailable}
                  scannedMac={scannedMac}
                />
              ),
              [WisaSequences.SPEAKER_TESTING]: (
                <ModuleProgrammingSpeakerTesting
                  scannedMac={scannedMac}
                  wisaProducts={wisaProducts?.data.list || []}
                  onChangeWisaProduct={handleChangeWisaProduct}
                  deviceType={deviceType}
                  isSequenceRunning={isSequenceRunning}
                />
              ),
              [WisaSequences.SYSTEM_TESTING]: (
                <ModuleProgrammingSystemTesting
                  scannedMac={scannedMac}
                  wisaProducts={wisaProducts?.data.list || []}
                  onChangeWisaProduct={handleChangeWisaProduct}
                  deviceType={deviceType}
                  isSequenceRunning={isSequenceRunning}
                />
              ),
              [WisaSequences.REMOTE_COMMANDS]: (
                <ModuleProgrammingRemoteCommands
                  deviceConfigurationPayload={deviceConfigurationPayload}
                  setDeviceConfigurationPayload={setDeviceConfigurationPayload}
                  customersList={customersList}
                  productList={productList}
                  modelList={modelList}
                  updatePayloadValue={updatePayloadValue}
                  regions={regionList ?? []}
                  isSequenceRunning={isSequenceRunning}
                  isRegionEnabled={isRegionEnabled}
                  isSpeakerPositionEnabled={isSpeakerPositionEnabled}
                  showAsscModeCheckbox={true}
                  changeAsscModeInRemote={changeAsscModeInRemote}
                  setChangeAsscModeInRemote={setChangeAsscModeInRemote}
                  macAddresses={macAddresses}
                  isStartExecutionButtonDisabled={macAddresses.length === 0 || isSequenceRunning}
                  onClickStartExecution={handleClickStartExecution}
                  beProductList={beProductList}
                  setDeviceType={setDeviceType}
                />
              ),
              [WisaSequences.DEVICE_CALIBRATION]: (
                <ModuleProgrammingDeviceCalibration
                  onChangeOverwriteCalData={handleChangeOverwriteCalData}
                  onChangeOverwriteMac={handleChangeOverwriteMac}
                  onChangeSkipDeviceProvisioning={handleChangeSkipDeviceProvisioning}
                  onClickAssignMac={handleClickAssignMac}
                  isOverwriteCalDataChecked={overwriteCal}
                  isOverwriteMacChecked={overwriteMac}
                  isSequenceRunning={isSequenceRunning}
                  isSkipDeviceProvisioning={skipDeviceProvisioning}
                  haveSkipDeviceProvisioningPermission={haveSkipDeviceProvisioningPermission}
                />
              ),
              [WisaSequences.SERIAL_PROGRAMMING]: (
                <button className={styles.startProgrammingButton} onClick={runSerialProgramming}>
                  Write Firmware
                </button>
              ),
            }[page]
          }
          <div className={`d-flex ${styles.commandsContainer}`}>
            <span>Programming Results</span>
            {sequence?.map((seq) => (
              <div className={styles.command} key={seq.id}>
                <label>
                  {seq.title} {seq.deviceResponse ? `(${seq.deviceResponse})` : null}
                  {seq.moveOn ? <Icons.MoveOnIcon /> : null}
                </label>
                <span className={styles?.[STATUS_CLASS_MAPPING[seq.status ?? COMMAND_STATUS.Pending]?.className]}>
                  {STATUS_CLASS_MAPPING[seq.status ?? COMMAND_STATUS.Pending]?.label}
                </span>
              </div>
            ))}{" "}
          </div>
        </div>
        <CommandResponse logs={logs} />
      </section>
      <StatusInfo infoModelOpen={infoModelOpen} setInfoModelOpen={setInfoModelOpen} />
      {!connected ? (
        <ConnectDialog
          connecting={connecting}
          setConnected={setConnected}
          setConnecting={setConnecting}
          defaultDriver={page === WisaSequences.SERIAL_PROGRAMMING ? "serial" : undefined}
        />
      ) : null}
    </div>
  );
};

export default ModuleProgramming;
