import { Dispatch, SetStateAction, useMemo } from "react";
import { useCustomerContext } from "../../hooks/useCustomerContext";
import { DeviceConfigurationPayload } from "../../interfaces/fe.interfaces";
import Select from "../Select";
import styles from "./styles.module.scss";
import Checkbox from "../Checkbox";
import { GetProductListResponse } from "../../interfaces/be.interfaces";

export interface DropdownItem {
  label: string;
  value: string | number;
  use_case?: string;
}

export interface ProductConfigDropdownsProps {
  deviceConfigurationPayload: DeviceConfigurationPayload;
  customersList?: DropdownItem[];
  productList?: DropdownItem[];
  modelList?: DropdownItem[];
  updatePayloadValue: (
    fieldName: keyof DeviceConfigurationPayload,
    value: string | number,
    sideEffectFields?: (keyof DeviceConfigurationPayload)[]
  ) => void;
  setDeviceConfigurationPayload: Dispatch<SetStateAction<DeviceConfigurationPayload>>;
  changeAsscModeInRemote: boolean;
  setChangeAsscModeInRemote: Dispatch<SetStateAction<boolean>>;
  regions: DropdownItem[];
  isSequenceRunning: boolean;
  isRegionEnabled: boolean;
  isSpeakerPositionEnabled: boolean;
  showAsscModeCheckbox: boolean;
  beProductList: GetProductListResponse | null;
  setDeviceType: Dispatch<SetStateAction<string>>;
}

const ProductConfigDropdowns = ({
  deviceConfigurationPayload,
  customersList,
  productList,
  modelList,
  updatePayloadValue,
  setDeviceConfigurationPayload,
  regions,
  isSequenceRunning,
  isRegionEnabled,
  isSpeakerPositionEnabled,
  showAsscModeCheckbox,
  changeAsscModeInRemote,
  setChangeAsscModeInRemote,
  beProductList,
  setDeviceType,
}: ProductConfigDropdownsProps) => {
  const { context: customerContext } = useCustomerContext();

  const haveSpeakerPosition = useMemo(
    () => Object.keys(deviceConfigurationPayload).includes("speaker_position"),
    [deviceConfigurationPayload]
  );

  return (
    <div className={styles.configOptions}>
      {!customerContext.vendorId && (
        <Select
          selectProps={{
            placeholder: !customersList?.length ? "Select Customer" : undefined,
            value: deviceConfigurationPayload.vendor_id,
            onChange: (event) => {
              updatePayloadValue("vendor_id", event.target.value, ["product_id", "customer_product_model_id"]);
              setDeviceType("");
            },
            className: styles.customSelectBox,
            disabled: isSequenceRunning,
          }}
          options={customersList ?? []}
        />
      )}
      {!customerContext.productId && (
        <Select
          selectProps={{
            placeholder: !productList?.length ? "Select Product" : undefined,
            value: deviceConfigurationPayload.product_id,
            onChange: (event) => {
              updatePayloadValue("product_id", +event.target.value, ["customer_product_model_id"]);

              const selectedProduct = beProductList?.data?.list
                ?.find((vendor) => vendor.vendor_id === deviceConfigurationPayload.vendor_id)
                ?.customer_products.find((product) => product.product_id === +event.target.value);

              if (selectedProduct) {
                setDeviceType(selectedProduct.product_type);
              } else {
                setDeviceType("");
              }
            },
            className: styles.customSelectBox,
            disabled: isSequenceRunning,
          }}
          options={productList ?? []}
        />
      )}
      {!customerContext.customerProductModelId && (
        <Select
          selectProps={{
            placeholder: !modelList?.length ? "Select Model" : undefined,
            value: deviceConfigurationPayload.customer_product_model_id,
            onChange: (event) => {
              updatePayloadValue("customer_product_model_id", event.target.value);
            },
            className: styles.customSelectBox,
            disabled: isSequenceRunning,
          }}
          options={modelList ?? []}
        />
      )}
      {haveSpeakerPosition && isSpeakerPositionEnabled && (
        <Select
          selectProps={{
            placeholder: "Select Position",
            value: deviceConfigurationPayload.speaker_position,
            onChange: (event) => {
              setDeviceConfigurationPayload((e) => ({
                ...e,
                speaker_position: event.target.value,
              }));
            },
            className: styles.customSelectBox,
            disabled: isSequenceRunning,
          }}
          options={[
            { label: "Left", value: "left" },
            { label: "Right", value: "right" },
          ]}
        />
      )}
      <input
        placeholder="Enter serial number"
        onChange={(event) => {
          setDeviceConfigurationPayload((e) => ({
            ...e,
            serial_number: event.target.value,
          }));
        }}
        className={styles.serialNumberInput}
        value={deviceConfigurationPayload.serial_number}
        disabled={isSequenceRunning}
      />
      {isRegionEnabled && (
        <Select
          selectProps={{
            placeholder: "Select Region",
            value: deviceConfigurationPayload.region ?? "",
            onChange: (event) => {
              setDeviceConfigurationPayload((e) => ({
                ...e,
                region: event.target.value,
              }));
            },
            disabled: regions?.length === 1 || isSequenceRunning,
            className: styles.customSelectBox,
          }}
          options={regions}
        />
      )}
      {showAsscModeCheckbox && (
        <Checkbox
          label="Update Association Mode in Remote?"
          name="updateAsscMode"
          onChange={(event) => setChangeAsscModeInRemote(event.target.checked)}
          checked={changeAsscModeInRemote}
        />
      )}
    </div>
  );
};

export default ProductConfigDropdowns;
