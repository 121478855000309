import { Formik, FormikHelpers } from "formik";
import { UserFormInitValues } from "../../interfaces/fe.interfaces";
import styles from "./styles.module.scss";
import Form from "./Form";
import { http } from "../../api/utils/http";
import { toast } from "react-toastify";
import { inviteUser } from "../../api/config";
import { PostOrPutUserResponse } from "../../interfaces/be.interfaces";
import { AxiosResponse } from "axios";
import { validateAddOrEditUser } from "../../utils/validationSchemas";
import { ROLE_ID } from "../../utils/constants";

interface InviteUserDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
}

// Initial values for invite user form
const initialValues: UserFormInitValues = {
  vendor_id: "",
  email: "",
  name: "",
  password: "",
  role_id: ROLE_ID.TECHNICIAN,
  confirmPassword: "",
  isPasswordFieldVisible: false,
  role: null,
  customer: null,
  customer_point_of_contact: false,
  manage_customer_id: null,
  managed_product_ids: [],
};

const InviteUserDialog = (props: InviteUserDialogProps) => {
  // Handler for form submit to save user
  const handleInviteUser = (values: UserFormInitValues, { setSubmitting }: FormikHelpers<UserFormInitValues>): void => {
    const payload = {
      role_id: values.role_id,
      name: values.name,
      email: values.email,
      vendor_id: values.vendor_id,
    };

    // Success callback for /invite-user API
    const onSuccess = ({ data: response }: AxiosResponse<PostOrPutUserResponse>) => {
      setSubmitting(false);
      if (response?.status) {
        // Display success toast and call parent callback to refresh the table
        toast.success(response?.message);
        props.callback();

        // Close the dialog
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /invite-user API
    http.makePostRequest<PostOrPutUserResponse, { message: string }>(
      inviteUser,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      payload
    );
  };

  return (
    <>
      <div className={styles.dialogContainer}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleInviteUser}
          onReset={props.onCancelClick}
          validationSchema={validateAddOrEditUser}>
          {(props) => <Form {...props} isEditForm={false} invite={true} />}
        </Formik>
      </div>
    </>
  );
};

export default InviteUserDialog;
