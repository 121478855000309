import { useCallback, useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { getCustomers, getRegistrationRequests, moveToPOC } from "../../api/config";
import AccessGuard from "../../components/Guards/AccessGuard";
import Spinner from "../../components/Spinner";
import { useFetch } from "../../hooks/useFetch";
import { GetCustomersResponse, RegistrationRequestsResponse, UpdateItemResponse } from "../../interfaces/be.interfaces";
import { PERMISSIONS } from "../../utils/constants";
import styles from "../ManageUser/styles.module.scss";
import Modal from "../../components/Modal/Modal";
import Select from "../../components/Select";
import { SelectOption } from "../../interfaces/fe.interfaces";
import { http } from "../../api/utils/http";
import { toast } from "react-toastify";

const RegistrationRequests = () => {
  const [isModalOpen, setIsModalOpen] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);
  const [customerList, setCustomerList] = useState<SelectOption<string | null>[]>([]);

  const [vendorId, setVendorId] = useState<string>("");

  // Hook to get the list of the user
  const {
    loading: usersLoading,
    data: user,
    error: userError,
    run: runUsers,
  } = useFetch<RegistrationRequestsResponse>({
    url: `${getRegistrationRequests}`,
    runOnMount: true,
  });

  const usersList = user?.data?.list;

  /**
   * This function is responsible for handling search
   */
  const onSearch = useCallback(
    (searchValue: string) => {
      const value = searchValue.trim();

      setSearchText(value);

      if (timer) clearTimeout(timer);

      setTimer(
        setTimeout(() => {
          runUsers({
            url: `${getRegistrationRequests}`,
          });
        }, 500)
      );
    },
    [runUsers, timer]
  );

  /**
   * Fetch list of customers to show in dropdown
   * only active customers are displayed in dropdown
   */
  useFetch<GetCustomersResponse>({
    runOnMount: true,
    url: getCustomers,
    onSuccess: (response) => {
      // Prepare customer data in option format to show in dropdown and filter inactive customers
      const customerOptions: SelectOption<string>[] = response.data?.data?.customers
        ?.filter((item) => item.is_enable)
        .map((customer) => ({
          label: customer.customer_name,
          value: customer.vendor_id,
        }));

      setCustomerList(customerOptions);
    },
  });

  const handleSendToPOC = useCallback(() => {
    if (!vendorId) {
      toast.error("Please select a customer");
    }
    const onSuccess = () => {
      setIsModalOpen(null);
      toast.success("Registration forwarded to POC for approval");
      runUsers({
        url: `${getRegistrationRequests}`,
      });
    };

    const payload = {
      requestId: isModalOpen,
      vendorId,
    };

    http.makePostRequest<UpdateItemResponse, { message: string }>(
      moveToPOC,
      onSuccess,
      (error) => {
        toast.error(error?.response?.data?.message || "Something went wrong");
      },
      payload
    );
  }, [isModalOpen, vendorId, runUsers]);

  return (
    <>
      <div className={styles.pageContainer}>
        <Tooltip id="info-tooltip" />
        <div className={`${styles.pageHeader} d-flex justify-content-between`}>
          <p>Registration Requests</p>
          <div className={`${styles.headerActions} d-flex justify-content-between align-item-center`}>
            <input type="text" value={searchText} placeholder="Search User" onChange={(e) => onSearch(e.target.value)} />
          </div>
        </div>
        {userError ? <div className="errorAlert">{userError}</div> : null}
        <div className={styles.tableResponsive}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Vendor</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <AccessGuard permission={[PERMISSIONS.USER_UPDATE, PERMISSIONS.USER_DELETE, PERMISSIONS.USER_ENABLE_DISABLE]}>
                  <th className={styles.action}>Action</th>
                </AccessGuard>
              </tr>
            </thead>
            <tbody>
              {usersLoading ? (
                <tr>
                  <td colSpan={4}>
                    <Spinner />
                  </td>
                </tr>
              ) : usersList?.length ? (
                usersList?.map((item) => {
                  return (
                    <tr key={item.request_id}>
                      <td>{item.customer_name}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.role_id}</td>
                      <td className={styles.switchinfo}>
                        <button onClick={() => setIsModalOpen(item.request_id)} className={styles.approveButton}>
                          Send to POC
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className={`${styles.emptyPage} d-flex justify-content-center align-item-center`}>
                      <span className={styles.noDataFound}>No User Found</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Modal isModalOpen={isModalOpen !== null}>
            <p style={{ marginTop: 0 }}>Assign to customer</p>
            <div className={styles.assignToContainer}>
              <Select
                selectProps={{
                  value: vendorId,
                  placeholder: "Select Customer",
                  onChange: (event) => setVendorId(event.target.value),
                }}
                options={customerList}
              />

              <button onClick={() => handleSendToPOC()} className={styles.approveButton}>
                Submit
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default RegistrationRequests;
