import { useState, useCallback, ChangeEvent, useMemo, MouseEvent } from "react";
import styles from "./styles.module.scss";
import { Icons } from "../../../assets/images";
import { getUsers, enableDisableUser, deleteUser, approveUser } from "../../api/config";
import { GetUsersResponse, PostOrPutUserResponse } from "../../interfaces/be.interfaces";
import Spinner from "../../components/Spinner";
import Switch from "../../components/Switch";
import { useFetch } from "../../hooks/useFetch";
import { toast } from "react-toastify";
import "react-tooltip/dist/react-tooltip.css";
import EditUserDialog from "../../components/AddOrEditUser/EditUser";
import AddUserDialog from "../../components/AddOrEditUser/AddUser";
import DeleteConfirmation from "../../components/ConfirmationDialog";
import { AxiosResponse } from "axios";
import { http } from "../../api/utils/http";
import { UserFormInitValues } from "../../interfaces/fe.interfaces";
import { PERMISSIONS } from "../../utils/constants";
import AccessGuard from "../../components/Guards/AccessGuard";
import { Tooltip } from "react-tooltip";
import InviteUserDialog from "../../components/AddOrEditUser/InviteUser";

const INITIAL_LIMIT = 15;
const INITIAL_OFFSET = 0;
const INITIAL_PAGES = [1, 2, 3];

const ManageUser = () => {
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [inviteDialogVisible, setInviteDialogVisible] = useState(false);
  const [editUserDetails, setEditUserDetails] = useState<UserFormInitValues | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [total, setTotal] = useState<number>(0);
  const [pages, setPages] = useState(INITIAL_PAGES);
  const [deleteUserId, setDeleteUserId] = useState<null | number>(null);
  const [limit, setLimit] = useState(INITIAL_LIMIT);
  const [offset, setOffset] = useState<number>(INITIAL_OFFSET);
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);

  // Hook to get the list of the user
  const {
    loading: usersLoading,
    data: user,
    error: userError,
    run: runUsers,
  } = useFetch<GetUsersResponse>({
    url: `${getUsers}?limit=${limit}&${offset}&search=${searchText}`,
    runOnMount: true,
    onSuccess: (res) => {
      setTotal(res.data.data?.total);
    },
  });

  const usersList = user?.data?.list;

  // Hook to update the status of the user
  const { run: runStatusUpdate, error: statusUpdateError } = useFetch<PostOrPutUserResponse>({
    url: `${getUsers}?limit=${limit}&${offset}&search=${searchText}`,
    runOnMount: false,
    onSuccess: (res) => {
      toast.success(res?.data?.message);

      // if successful then re-run the user query to get the updated list
      runUsers({
        showLoader: false,
      });
    },
  });

  const handleChangeStatus = (id: number): void => {
    runStatusUpdate({
      url: `${enableDisableUser}${id}`,
    });
  };

  const createOrEditUserCallback = () => {
    // if successful then re-run the user query to get the updated list
    runUsers({
      showLoader: false,
    });
  };

  /**
   * Handles change event for the limit dropdown
   * reset all pagination related states and fetch data for the new limit
   */
  const handleChangeLimit = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const newLimit = Number(e.target.value);
    const newOffset = INITIAL_OFFSET;

    runUsers({
      url: `${getUsers}?limit=${newLimit}&offset=${newOffset}&search=${searchText}`,
    });

    setOffset(newOffset);
    setLimit(newLimit);
    setPages(INITIAL_PAGES);
  }, []);

  const currentPage = useMemo(() => Math.ceil(offset / limit) + 1, [offset, limit]);
  const totalPages = useMemo(() => Math.ceil(total / limit), [total, limit]);

  /**
   * This function is responsible for finding the page numbers to be shown in the pagination
   * Maximum 3 page numbers are shown in the pagination
   * @param {string} btnAction - action of the button clicked, can be next or prev
   * @param {number} newOffset - new offset to be set
   */
  const findPageNumbers = useCallback(
    (btnAction: string, newOffset: number) => {
      const newCurrentPage = Math.ceil(newOffset / limit) + 1;

      // if current page or next page is available then change page numbers on UI
      if (newCurrentPage < totalPages && newCurrentPage > 1) {
        setPages([newCurrentPage - 1, newCurrentPage, newCurrentPage + 1]);
      }
    },
    [pages, totalPages, currentPage, limit]
  );

  /**
   * This function is responsible for handling the next and prev button click
   * data-action attribute is used to identify the button clicked
   */
  const onNextOrPrevClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const btnAction = e.currentTarget.getAttribute("data-action");
      let newOffset = offset;

      // Increase or decrease the offset based on the button clicked
      if (btnAction === "next") {
        newOffset = newOffset + limit;
      } else if (btnAction === "prev") {
        newOffset = newOffset - limit;
      }

      // Re-generate the page numbers to be shown in the pagination
      btnAction && findPageNumbers(btnAction, newOffset);

      // fetch data for the new offset
      runUsers({
        url: `${getUsers}?limit=${limit}&offset=${newOffset}&search=${searchText}`,
      });
      setOffset(newOffset);
    },
    [offset, pages, totalPages, currentPage, runUsers, findPageNumbers, limit]
  );

  /**
   * Handles click event for the page numbers in the pagination
   * Jumps to the page number clicked
   */
  const navigateToPage = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      // Find new offset based on the page number clicked using the data-page_number attribute
      const pageNumber = Number(e.currentTarget.getAttribute("data-page_number"));
      const newOffset = (pageNumber - 1) * limit;

      // Re-generate the page numbers to be shown in the pagination
      findPageNumbers(newOffset > offset ? "next" : "prev", newOffset);

      runUsers({
        url: `${getUsers}?limit=${limit}&offset=${newOffset}&search=${searchText}`,
      });
      setOffset(newOffset);
    },
    [offset, findPageNumbers, limit]
  );

  /**
   * Confirmation callback for delete confirmation
   * Calls /delete-wisa-user API for removing entity from database
   */
  const handleDeleteUser = (): void => {
    const onSuccess = ({ data: response }: AxiosResponse<PostOrPutUserResponse>) => {
      if (response?.status) {
        runUsers();
        toast.success(response?.message || "User deleted successfully.");
      } else {
        toast.error(response?.message);
      }
    };

    http.makeDeleteRequest<PostOrPutUserResponse, { message: string }>(deleteUser + deleteUserId, onSuccess, (error) => {
      toast.error(error?.response?.data?.message);
    });
    setDeleteUserId(null);
  };

  /**
   * This function is responsible for handling search
   */
  const onSearch = useCallback(
    (searchValue: string) => {
      const value = searchValue.trim();

      setSearchText(value);

      if (timer) clearTimeout(timer);

      setTimer(
        setTimeout(() => {
          runUsers({
            url: `${getUsers}?limit=${limit}&offset=${offset}&search=${value}`,
          });
        }, 500)
      );
    },
    [limit, offset, runUsers, timer]
  );

  const handleApproveRejectClick = useCallback((userId: number, isApproved: boolean) => {
    const onSuccess = ({ data: response }: AxiosResponse<PostOrPutUserResponse>) => {
      if (response?.status) {
        runUsers();
        toast.success(response?.message || "User status updated");
      } else {
        toast.error(response?.message);
      }
    };

    http.makePostRequest<PostOrPutUserResponse, { message: string }>(
      approveUser,
      onSuccess,
      (error) => {
        toast.error(error?.response?.data?.message);
      },
      {
        userId,
        isApproved,
      }
    );
  }, []);

  return (
    <>
      <div className={styles.pageContainer}>
        <Tooltip id="info-tooltip" />
        <div className={`${styles.pageHeader} d-flex justify-content-between`}>
          <p>Manage User</p>
          <div className={`${styles.headerActions} d-flex justify-content-between align-item-center`}>
            <input type="text" value={searchText} placeholder="Search User" onChange={(e) => onSearch(e.target.value)} />
            <AccessGuard permission={PERMISSIONS.USER_ADD}>
              <button onClick={() => setAddDialogVisible(true)}>Add</button>
            </AccessGuard>
            <AccessGuard permission={PERMISSIONS.USER_ADD}>
              <button onClick={() => setInviteDialogVisible(true)}>Invite</button>
            </AccessGuard>
          </div>
        </div>
        {userError || statusUpdateError ? <div className="errorAlert">{userError || statusUpdateError}</div> : null}
        <div className={styles.tableResponsive}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Vendor</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>User Status</th>
                <AccessGuard permission={[PERMISSIONS.USER_UPDATE, PERMISSIONS.USER_DELETE, PERMISSIONS.USER_ENABLE_DISABLE]}>
                  <th className={styles.action}>Action</th>
                </AccessGuard>
              </tr>
            </thead>
            <tbody>
              {usersLoading ? (
                <tr>
                  <td colSpan={4}>
                    <Spinner />
                  </td>
                </tr>
              ) : usersList?.length ? (
                usersList?.map((item) => {
                  return (
                    <tr key={item.user_id}>
                      <td>{item.customer.customer_name}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.role.role_name}</td>
                      <td style={{ textTransform: "capitalize" }}>{item.user_status}</td>
                      <AccessGuard permission={[PERMISSIONS.USER_UPDATE, PERMISSIONS.USER_DELETE, PERMISSIONS.USER_ENABLE_DISABLE]}>
                        <td className={styles.switchinfo}>
                          {item.user_status === "pending" ? (
                            <>
                              <button onClick={() => handleApproveRejectClick(item.user_id, true)} className={styles.approveButton}>
                                Approve
                              </button>
                              <button onClick={() => handleApproveRejectClick(item.user_id, false)} className={styles.approveButton}>
                                Reject
                              </button>
                            </>
                          ) : (
                            <>
                              {!item.is_default && (
                                <button
                                  onClick={() => handleApproveRejectClick(item.user_id, item.user_status === "rejected")}
                                  className={styles.approveButton}>
                                  {item.user_status === "rejected" ? "Approve" : "Reject"}
                                </button>
                              )}
                            </>
                          )}
                          <AccessGuard permission={PERMISSIONS.USER_UPDATE}>
                            <Icons.EditIcon
                              onClick={() =>
                                setEditUserDetails({
                                  role_id: item.role.role_id,
                                  user_id: item.user_id,
                                  email: item.email,
                                  name: item.name,
                                  vendor_id: item.vendor_id.toString(),
                                  password: "",
                                  confirmPassword: "",
                                  role: item.role,
                                  customer: item.customer,
                                  customer_point_of_contact: item.customer_point_of_contact,
                                  manage_customer_id: item.manage_customer_id,
                                  managed_product_ids: item.managed_product_ids.map((value) => value.customer_product_id),
                                })
                              }
                            />
                          </AccessGuard>
                          <AccessGuard permission={PERMISSIONS.USER_DELETE}>
                            <Icons.TrashIcon
                              onClick={() => !item.is_default && setDeleteUserId(item?.user_id)}
                              className={item.is_default ? styles.disabled : ""}
                              data-tooltip-id="info-tooltip"
                              data-tooltip-place="left"
                              data-tooltip-content={item.is_default ? "You can not delete default user." : ""}
                            />
                          </AccessGuard>
                          <AccessGuard permission={PERMISSIONS.USER_ENABLE_DISABLE}>
                            <Switch
                              checked={item.is_enable}
                              name={"user"}
                              data-tooltip-id="info-tooltip"
                              disabled={item.is_default}
                              data-tooltip-place="left"
                              data-tooltip-content={item.is_default ? "You can not disable default user." : ""}
                              onChange={() => !item.is_default && handleChangeStatus(item?.user_id)}
                            />
                          </AccessGuard>
                        </td>
                      </AccessGuard>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className={`${styles.emptyPage} d-flex justify-content-center align-item-center`}>
                      <span className={styles.noDataFound}>No User Found</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {!usersLoading && (
            <div className={styles.paginationContainer}>
              <select onChange={handleChangeLimit} value={limit}>
                {[INITIAL_LIMIT, 30, 50, 100].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <button onClick={onNextOrPrevClick} data-action="prev" disabled={offset === 0}>
                <Icons.ChevronLeft />
              </button>
              {pages?.map((item) => (
                <button
                  key={item}
                  className={`${item === currentPage ? styles.active : ""}`}
                  data-page_number={item}
                  onClick={navigateToPage}
                  disabled={item > totalPages}>
                  {item}
                </button>
              ))}
              <button onClick={onNextOrPrevClick} data-action="next" disabled={offset + limit >= total}>
                <Icons.ChevronRight />
              </button>
            </div>
          )}
        </div>
        {deleteUserId ? (
          <DeleteConfirmation
            title="Delete User"
            description="Are you sure you want to delete the user?"
            onConfirmClick={handleDeleteUser}
            onDeclineClick={() => setDeleteUserId(null)}
          />
        ) : null}
        {addDialogVisible ? <AddUserDialog callback={createOrEditUserCallback} onCancelClick={() => setAddDialogVisible(false)} /> : null}
        {inviteDialogVisible ? (
          <InviteUserDialog callback={createOrEditUserCallback} onCancelClick={() => setInviteDialogVisible(false)} />
        ) : null}
        {editUserDetails ? (
          <EditUserDialog
            id={editUserDetails?.vendor_id}
            initialValues={editUserDetails}
            callback={createOrEditUserCallback}
            onCancelClick={() => setEditUserDetails(null)}
          />
        ) : null}
      </div>
    </>
  );
};

export default ManageUser;
