import Spinner from "../Spinner";
import styles from "./styles.module.scss";
import { getCustomers, getProductsList, getRoleInfo } from "../../api/config";
import { useCallback, useMemo, useState } from "react";
import { SelectOption, UserFormProps } from "../../interfaces/fe.interfaces";
import { GetCustomersResponse, GetProductListResponse, RoleInfoResponse } from "../../interfaces/be.interfaces";
import { useFetch } from "../../hooks/useFetch";
import Select from "../Select";
import { Icons } from "../../../assets/images";
import Checkbox from "../Checkbox";

/**
 * Common form component for AddUser and EditUser
 * Takes in all the props from Formik component
 */
const Form = ({
  values,
  handleChange,
  touched,
  errors,
  handleSubmit,
  handleReset,
  setFieldValue,
  handleBlur,
  isSubmitting,
  enableReinitialize,
  isEditForm,
  invite = false,
}: UserFormProps) => {
  const [customerList, setCustomerList] = useState<SelectOption<string | null>[]>([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState({
    password: false,
    confirmPassword: false,
  });

  // Get list of customer info options to display in dropdown selection
  const {
    loading: roleInfoLoading,
    error: customerError,
    data: roleInfoData,
  } = useFetch<RoleInfoResponse>({
    url: getRoleInfo,
    runOnMount: true,
  });

  /**
   * Fetch list of customers to show in dropdown
   * only active customers are displayed in dropdown
   */
  const { loading: customerInfoLoading, data: customerInfoList } = useFetch<GetCustomersResponse>({
    runOnMount: true,
    url: getCustomers,
    onSuccess: (response) => {
      // Prepare customer data in option format to show in dropdown and filter inactive customers
      const customerOptions: SelectOption<string>[] = response.data?.data?.customers
        ?.filter((item) => item.is_enable)
        .map((customer) => ({
          label: customer.customer_name,
          value: customer.vendor_id,
        }));

      setCustomerList(customerOptions);
    },
  });

  // Handle role change
  const onChangeRole = useCallback(
    (value: string) => {
      const role = roleInfoData?.data.list.find((item) => item.role_id === value);

      setFieldValue("role", role);
      setFieldValue("role_id", value);

      if (role && role.is_wisa_role) {
        // Find customer
        const customer = customerInfoList?.data.customers.find((item) => item.is_wisa === true);

        if (customer) {
          setFieldValue("customer", customer);
          setFieldValue("vendor_id", customer.vendor_id);
        }
      }
    },
    [roleInfoData, customerInfoList]
  );

  // Handle customer change
  const onChangeCustomer = useCallback(
    (value: string) => {
      const customer = customerInfoList?.data.customers.find((item) => item.vendor_id === value);
      setFieldValue("customer", customer);
      setFieldValue("vendor_id", value);
      setFieldValue("managed_product_ids", []);
    },
    [customerInfoList]
  );

  const onChangeIsPasswordVisible = useCallback((value: boolean) => {
    // Reset password and confirm password field when uncheck the password visible field
    if (!value) {
      setFieldValue("password", null);
      setFieldValue("confirmPassword", null);
    }

    setFieldValue("isPasswordFieldVisible", value);
  }, []);

  const { data: productList } = useFetch<GetProductListResponse>({
    url: getProductsList,
    runOnMount: true,
  });

  const productsOfCustomer = useMemo(() => {
    if (!values.vendor_id) return [];

    const productsOfVendor =
      productList?.data.list.find((v) => v.vendor_id === (values.vendor_id === "1" ? values.manage_customer_id : values.vendor_id))
        ?.customer_products ?? [];

    return productsOfVendor
      .map((value) => ({
        label: value.product_name,
        value: value.customer_product_id,
      }))
      .filter((v) => !values.managed_product_ids.includes(v.value));
  }, [productList, values.vendor_id, values.managed_product_ids, values.manage_customer_id]);

  const selectedManageProductItem = useMemo(() => {
    if (values.managed_product_ids.length) {
      const productsOfVendor =
        productList?.data.list.find((v) => v.vendor_id === (values.vendor_id === "1" ? values.manage_customer_id : values.vendor_id))
          ?.customer_products ?? [];

      return productsOfVendor
        .map((value) => ({
          label: value.product_name,
          value: value.customer_product_id,
        }))
        .filter((v) => values.managed_product_ids.includes(v.value));
    }

    return [];
  }, [productList, values.vendor_id, values.managed_product_ids, values.manage_customer_id]);

  return (
    <form onSubmit={handleSubmit}>
      {customerError ? <div className="errorAlert">{customerError}</div> : null}
      <div className={styles.dialog}>
        {isSubmitting || roleInfoLoading || customerInfoLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <Select
                  selectProps={{
                    name: "role",
                    value: values.role_id,
                    placeholder: "Select User Role",
                    onChange: (event) => onChangeRole(event.target.value),
                  }}
                  optionLabel="role_name"
                  optionValue="role_id"
                  options={roleInfoData?.data.list ?? []}
                />
                {errors?.role_id && touched.role_id ? <p className={styles.error}>{errors?.role_id}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center `}>
                <Select
                  selectProps={{
                    value: values.vendor_id,
                    placeholder: "Select Customer",
                    onChange: (event) => onChangeCustomer(event.target.value),
                    disabled: values.role?.is_wisa_role,
                  }}
                  options={customerList}
                />
                {touched.vendor_id && errors.vendor_id && <p className={styles.error}>{errors.vendor_id}</p>}
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <input
                  name="email"
                  type="text"
                  placeholder={"Enter User Email"}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors?.email && touched.email ? <p className={styles.error}>{errors?.email}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <input
                  name="name"
                  type="text"
                  placeholder={"Enter User Name"}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors?.name && touched.name ? <p className={styles.error}>{errors?.name}</p> : null}
              </div>
            </div>

            {values.isPasswordFieldVisible && (
              <div className="w-100 d-flex justify-content-around">
                <div className={`${styles.inputContainer} d-flex justify-content-between`}>
                  <div className={styles.passwordContainer}>
                    <input
                      name="password"
                      type={isPasswordVisible.password ? "text" : "password"}
                      placeholder={"Enter User Password"}
                      value={values?.password ?? ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <button
                      type="button"
                      onClick={() => setIsPasswordVisible({ ...isPasswordVisible, password: !isPasswordVisible.password })}>
                      {!isPasswordVisible.password ? <Icons.EyeOn /> : <Icons.EyeOff />}
                    </button>
                  </div>
                  {errors?.password && touched.password ? <p className={styles.error}>{errors?.password}</p> : null}
                </div>
                <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                  <div className={styles.passwordContainer}>
                    <input
                      name="confirmPassword"
                      type={isPasswordVisible.confirmPassword ? "text" : "password"}
                      placeholder={"Enter Password Again"}
                      value={values.confirmPassword ?? ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <button
                      type="button"
                      onClick={() => setIsPasswordVisible({ ...isPasswordVisible, confirmPassword: !isPasswordVisible.confirmPassword })}>
                      {!isPasswordVisible.confirmPassword ? <Icons.EyeOn /> : <Icons.EyeOff />}
                    </button>
                  </div>
                  {errors?.confirmPassword && touched.confirmPassword ? <p className={styles.error}>{errors?.confirmPassword}</p> : null}
                </div>
              </div>
            )}

            {invite === false ? (
              <>
                {values.role?.is_wisa_role ? (
                  <div className="w-100 d-flex justify-content-around">
                    <div className={`${styles.inputContainer} ${styles.fullWidthSelect} d-flex justify-content-between align-item-center`}>
                      <Select
                        selectProps={{
                          value: values.manage_customer_id || "",
                          onChange: (event) => {
                            setFieldValue("manage_customer_id", event.target.value ? event.target.value : null);
                            setFieldValue("managed_product_ids", []);
                          },
                        }}
                        options={[{ label: "Select Customer To Manage", value: "" }, ...customerList]}
                      />
                      {errors?.manage_customer_id && touched.manage_customer_id ? (
                        <p className={styles.error}>{errors?.manage_customer_id}</p>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                <div className="w-100 d-flex justify-content-around">
                  <div className={`${styles.inputContainer} ${styles.fullWidthSelect} d-flex justify-content-between align-item-center`}>
                    <Select
                      selectProps={{
                        onChange: (e) => setFieldValue("managed_product_ids", [...values.managed_product_ids, +e.target.value]),
                        disabled: !values.vendor_id,
                      }}
                      options={[{ label: "Select Products To Manage", value: "" }, ...productsOfCustomer]}
                    />
                    {errors?.manage_customer_id && touched.manage_customer_id ? (
                      <p className={styles.error}>{errors?.manage_customer_id}</p>
                    ) : null}
                  </div>
                </div>
                {selectedManageProductItem.length ? (
                  <div className={`${styles.tagsContainer}`}>
                    {selectedManageProductItem.map((value) => (
                      <div key={value.value} className={styles.tagsItem}>
                        {value.label}
                        <span
                          onClick={() => {
                            setFieldValue(
                              "managed_product_ids",
                              values.managed_product_ids.filter((v) => v !== value.value)
                            );
                          }}>
                          x
                        </span>
                      </div>
                    ))}
                  </div>
                ) : null}

                <div className={`${styles.passwordCheckBox} w-100 d-flex`}>
                  <Checkbox
                    label="Is user customer point of contact?"
                    name="customer_point_of_contact"
                    checked={values?.customer_point_of_contact ?? false}
                    onChange={() => setFieldValue("customer_point_of_contact", !values?.customer_point_of_contact)}
                  />
                </div>
              </>
            ) : null}

            {isEditForm && (
              <div className={`${styles.passwordCheckBox} w-100 d-flex`}>
                <Checkbox
                  label="Change Password?"
                  name="isPasswordFieldVisible"
                  checked={values?.isPasswordFieldVisible ?? false}
                  onChange={() => onChangeIsPasswordVisible(!values.isPasswordFieldVisible)}
                />
              </div>
            )}

            <div className="w-100 d-flex justify-content-around">
              <button className={styles.dialogBtn} type="submit" disabled={isSubmitting}>
                {enableReinitialize ? "Update" : "Add"}
              </button>
              <button type="button" className={styles.dialogBtn} onClick={handleReset}>
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default Form;
